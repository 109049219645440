// define grade colors
@sehrgut: rgba(54, 196, 42, 1.0);
@gut: rgba(242, 183, 3, 1.0);
@befriedigend: rgba(239, 224, 21, 1.0);
@ausreichend: rgba(241, 141, 79, 1.0);
@mangelhaft: rgba(233, 92, 13, 1.0);
@ungenuegend: #dd5305;

body {
    background: #fbfbfb;
    #gradient > .vertical(#f1f1f1,#fbfbfb,5%,35%);
    font-weight: 300;
    padding-top: 100px;
}

a {text-decoration: underline;}
a:hover {text-decoration: none;}

@media (min-width: @screen-sm-min) {
    body.small-padding-top {
        padding-top: 130px;
    }
    body {
        padding-top: 250px;
    }
}

.no-gutter [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}

.borderless td, .borderless th {
    border: none !important;
    padding: 0 !important;
}


.btn-default {
    #gradient > .vertical(#f6f6f6, #e3e3e3);
    border: 0;
}

.btn-primary {
    #gradient > .vertical(#daa155, @brand-primary);
    border: 0;
}

.btn-primary:hover {
    background: @brand-primary;
}

strong {font-weight: 600;}

.radio, .checkbox {margin: 0; font-weight: 300;}
.radio-inline, .checkbox-inline {font-weight: 300;}
input[type="radio"], input[type="checkbox"] {margin: 7px 0 0;}

.well {
    border-radius: 0;
    .box-shadow(0 0 0 #fff);
}

.breadcrumb {
    padding: 8px 0;
    background: none;
}
.breadcrumb > li + li:before {
    color: #666;
    font-family: FontAwesome;
    content: "\f101";
    padding: 0 5px;
}

.btn {
    border-radius: 0;
    text-decoration: none;
}



// Nav
.navbar {
    border: 0 !important;
    margin: 0 !important;
    .box-shadow (0 0 5px #777);
}
.navbar-collapse {
    border-top: 0;
}

.navbar-nav > li > a {
    text-transform: uppercase;
    font-weight: 400;
    text-decoration: none;
}

.btn-nav-right {
    padding: 0 12px;
    margin: 0 0 18px;
    height: 40px;
    line-height: 40px;
    text-transform: uppercase;
    border: 0;
    font-weight: 400;
    text-decoration: none;
}

@media (min-width: @screen-md-min) {

    .navbar-nav, .nav {
        display: inline-block;
        float: none;
        padding: 0 !important;
        margin: 0 auto !important;
        text-align: center;
    }

    .navbar-nav > li {
        display: inline;
        float: none;
    }

    .navbar-nav > li > a {
        line-height: 100px;
        // padding: 0 15px !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        display: inline;
    }

    .btn-nav-right {
        margin: 30px 0 0;
        text-align: right;
    }

}


// Searchbar
#searchbar {
    width: 100%;
    background: #616d81;
}

#searchbar.disabled {display: none;}

@media (min-width: @screen-md-min) {
    #searchbar {
        position: fixed;
        top: 100px;
        left: 0;
        z-index: 999;
    }
}


#searchbar form .form-group, #searchbar form input {
    width: 100%;
}

#searchbar form .form-group {
    margin-top: 15px;
}

#searchbar form .form-control {
    border: 0;
    border-radius: 0;
}

#searchbar #in {
    display: inline-block;
    color: #ffffff;
    text-align: center;
    width: 100%;
}

.btn-searchbar {
    padding: 0 12px;
    border: 0;
    #gradient > .vertical(#daa155, @brand-primary);
    height: 42px;
    line-height: 42px;
    margin: 15px 0;
    display: block;
    text-decoration: none;
}
.btn-searchbar:hover {
    background: @brand-primary;
}

@media (min-width: @screen-sm-min) {
    #searchbar #in {height: 72px; line-height: 72px;}
    #searchbar .searchbar-first-col {padding-right: 0;}
    #searchbar .searchbar-second-col {padding: 0;}
    #searchbar .searchbar-third-col {padding-left: 0;}
    .btn-searchbar {display: inline-block;}
}


// Login, page
#form-box {
    background: #fff;
    padding: 15px;
    margin: 15px 0 0;
    border: 1px solid #e6e6e6;
}

#form-box form {
    margin-bottom: 30px;
}

@media (min-width: @screen-sm-min) {
    #login #form-box .register {text-align: right;}
}

#login h1 {
  color: @brand-secondary;
  font-size: 20px;
  margin-top: 0;
}

// Doc Register, page

#doc-register h1 {
  color: @brand-secondary;
  font-size: 20px;
  margin-top: 0;
}

#doc-register .form-group {
    margin-bottom: 5px;
}

ul.parsley-errors-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;
}

li.parsley-required {
    color: @brand-primary;
    font-size: @font-size-small;
}

#doc-register .checkbox .parsley-errors-list {
    margin-left: 5px;
}

// Login Bereich, page

    #login-area-profile {
        margin: 0 0 10px;
    }

    #login-area-profile h2 {
        font-size: 16px;
        color: @brand-secondary;
        margin: 0 0 10px;
    }

    #login-area-content h1 {
        font-size: 20px;
        margin-top: 0;
        color: @brand-secondary;
    }

    #login-area-nav-container {
        background: #ffffff;
        border: 1px solid #e6e6e6;
        margin: 0 0 10px;
    }

    nav#login-area-nav ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    nav#login-area-nav ul > li {
        border-bottom: 1px solid #e6e6e6;
        position: relative;
    }

    nav#login-area-nav ul > li:last-child {
        border: 0;
    }

    nav#login-area-nav ul > li.active {
        background: @brand-secondary;
    }

    nav#login-area-nav ul li a {
        text-decoration: none;
        font-weight: bold;
        padding: 5px 10px;
        display: inline-block;
    }


    nav#login-area-nav ul li.has-submenu:after {
        font-family: 'FontAwesome';
        font-size: 20px;
        content: "\f107";
        position: absolute;
        top: 0;
        right: 10px;
    }

    nav#login-area-nav ul li.active.has-submenu:after {
        color: #ffffff;
    }

    nav#login-area-nav ul li.has-submenu.expanded:after {
        font-family: 'FontAwesome';
        font-size: 20px;
        content: "\f106";
        position: absolute;
        top: 0;
        right: 10px;
    }

    nav#login-area-nav ul > li.active > a {
        color: #ffffff;
    }

    nav#login-area-nav ul > li:hover {
        cursor: pointer;
    }

    nav#login-area-nav ul > li > a:hover  {color: @brand-primary;}
    //nav#login-area-nav ul > li.expanded:hover > a {color: @gray-light;}

    nav#login-area-nav ul > li.active:hover {
        background: @brand-secondary;
        cursor: normal;
    }

    nav#login-area-nav ul > li.active > a:hover {color: #ffffff;}

    nav#login-area-nav ul > li > ul {
        display: none;
        #gradient > .vertical(#ffffff, #e6e6e6);
    }

    nav#login-area-nav ul > li > ul > li {
        border-bottom: 1px solid #ffffff;
    }

    nav#login-area-nav ul > li > ul > li.active, nav#login-area-nav ul > li > ul > li.active:hover {
        background: lighten(@brand-secondary, 10%);
    }


    nav#login-area-nav ul > li > ul > li:last-child {
        border: 0;
    }

    nav#login-area-nav ul > li > ul > li > a {
        padding: 5px 10px 5px 20px;
    }


    .login-area-info-box {
        background: #ffffff;
        border: 1px solid #e6e6e6;
        padding: 15px 10px;
        margin: 0 0 10px;
    }

    .login-area-info-box h2 {
        font-size: 16px;
        color: @brand-secondary;
        margin: 0 0 10px;
    }


    // Charts

    canvas {
        width: 100% !important;
        max-width: 800px;
        height: auto !important;
    }

    // Tabs

    .tab-content {
        border-left: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;
        border-bottom: 1px solid #e6e6e6;
        padding: 15px;
        background: #fff;
    }

    .nav-tabs {
        width: 100%;
    }

    @media (min-width: @screen-md-min) {
        .nav-tabs {margin-bottom: -10px !important;}
    }

    .nav-tabs > li > a {
        border-radius: 0;
        line-height: 1;
        padding: 10px 15px;
        text-decoration: none;
    }

// Für Ärzte, page

#for-docs .jumbotron {
    background: #fff;
    padding: 45px 0 0;
    margin-top: -30px;
}

#for-docs .row {
    margin: 0 0 60px;
}

#for-docs .jumbotron .row {
    margin: 0;
}

#for-docs h1 {
    color: @brand-secondary;
    font-size: 22px;
}
#for-docs h2 {
    color: @brand-secondary;
    font-size: 20px;
}
#for-docs h3 {
    font-size: 16px;
}

#for-docs .well {
    min-height: 220px;
}

#for-docs .well h2 {
    margin-top: 0;
}

#for-docs .well a.phone {
    font-size: 32px;
    color: @brand-primary;
}

#for-docs .info-box {
    background: #e6e6e6;
}

#for-docs .info-box h2 {
    padding: 10px 0;
    text-align: center;
    font-size: 16px;
    line-height: 1.33;
    margin: 30px 0;
}

@media (min-width: @screen-sm-min) {
    #for-docs .info-box h2 {
        text-align: left;
    }
}

#for-docs .info-box .btn {
    display: block;
    margin: 30px 0;
}
#for-docs .info-box .row {margin: 0;}


// Paketübersicht, page


#packages .indicator {
    border-top: 2px solid #ccc;
    position: relative;
}
#packages .indicator:before {
    content: "#";
    width: 14px;
    height: 14px;
    border-radius: 14px;
    background: #ccc;
    position: absolute;
    top: -8px;
    right: 0;
    color: transparent;
}
#packages .indicator.completed {
    border-top: 2px solid @brand-primary;
}
#packages .indicator.completed:before {
    background: @brand-primary;
}

#packages h1 {
    font-size: 20px;
    color: @brand-secondary;
}

#packages .table thead {display: none;}
#packages .table td {
    text-align: center;
}

#packages tr.no-border td {
    border: 0;
}

#packages .table td.text-left {
    text-align: left;
    font-size: 13px;
}
#packages .table td.valign-bottom {
    vertical-align: bottom;
}

#packages .table td.grey-bg {background: #eeeeee;}
#packages .table td.green-bg {background: #cae7cb;}
#packages .table td.header-td {padding-top: 30px;}

#packages .table i {
    color: #37c428;
}


#packages .table tfoot .packages-info {
    font-style: italic;
}
#packages .table tfoot span.package-name {
    display: block;
}

#packages .table tfoot span.package-price {
    display: block;
    font-size: 26px;
    line-height: 1;
}

#packages .table tfoot .btn {
    display: block;
}

// Bewertung, page

#rate-doc h1.doc-name-rate {
    color: @brand-secondary;
    font-size: 20px;
    margin-top: 0;
}

#rate-doc .star-rating {display: inline-block; margin-right: 15px;}

#rate-doc .star-rating .rating-container {letter-spacing: 3px;}

// color classes

    #rate-doc .rating-six .rating-stars {color: @sehrgut !important;}
    #rate-doc .rating-five .rating-stars {color: @gut !important;}
    #rate-doc .rating-four .rating-stars {color: @befriedigend !important;}
    #rate-doc .rating-three .rating-stars {color: @ausreichend !important;}
    #rate-doc .rating-two .rating-stars {color: @mangelhaft !important;}
    #rate-doc .rating-one .rating-stars {color: @ungenuegend !important;}

#rate-doc .main-label {
    margin-bottom: 10px;
    font-weight: 600;
    line-height: 20px;
}

#rate-doc .rating-caption .label {
    display: inline-block;
    font-size: 13px;
    padding: 0;
    padding-left: 10px;
    color: @brand-secondary;
    background: none !important;
    font-weight: normal;
}

#rate-doc .rating-xs {
    font-size: 1em;
    line-height: 1em;
}


// breadcrumb and search
#doc-search .btn-search, #doc-profile .btn-search {
    display: block;
    padding: 0 12px;
    height: 42px;
    line-height: 42px;
    border: 0;
    #gradient > .vertical(#daa155, @brand-primary);
}
@media (min-width: @screen-sm-min) {
    #doc-search .btn-search, #doc-profile .btn-search {
        margin-left: 10px;
        display: inline-block;
    }
}

#doc-search .btn-search:hover, #doc-profile .btn-search:hover {
    background: @brand-primary;
}

// Arztsuche, page

#filter {background: #ffffff; padding: 15px; margin-top: 15px;}
@media (min-width: @screen-md-min) {
    #filter {margin-top: 0;}
}


#doc-search .doc-entry {
    padding: 30px 0;
    border-bottom: 1px solid #e6e6e6;
}

.rate-doc {
    margin-top: 15px;
}

@media (min-width: @screen-sm-min) {.rate-doc{margin-top: 0;}}

.rate-now {
    display: inline-block;
    font-weight: 600;
    padding: 0;
    text-align: center;
    height: 50px;
    line-height: 50px;
    width: 75%;
    #gradient > .vertical (#f6f6f6, #e3e3e3);
}

.average {
    display: inline-block;
    font-weight: 600;
    padding: 0;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    width: 25%;
    #gradient > .vertical (#5ce34e, #38c529);
}
.rate-now, .average {vertical-align: top;}
@media (min-width: @screen-sm-min) {
    .rate-now, .average {
        width: auto;
        padding: 0 15px;
    }
}

h1.doc-name {
    font-size: 20px;
    color: @brand-secondary;
    margin: 0;
}

.doc-pic img {
    border: 1px solid #e6e6e6;
}

ul.doc-advantages li {
    float: left;
    margin-right: 10px;
}

ul.doc-advantages li i {
    color: @brand-success;
}


// Arztprofil, page

#doc-profile .doc-name {
    margin-top: 15px;
}

@media (min-width: @screen-sm-min) {#doc-profile .rate-doc{margin-top: 0;}}

@media (min-width: @screen-sm-min) {
    #doc-profile #doc-info, #doc-profile #doc-advantages {width: 100%; border-right: 1px solid #e6e6e6;}
}


#doc-profile h2 {
    font-size: 17px;
}

#doc-profile h2.line {
    display: table;
    width: 100%;
}
#doc-profile h2.line > span, #doc-profile h2.line:after {
    display: table-cell;
}
#doc-profile h2.line:after {
    background: url(http://dummyimage.com/1x1/e6e6e6/e6e6e6.gif) repeat-x center;
    width: 100%;
    content: ' ';
}
#doc-profile h2.line > span {
    white-space: nowrap;
    padding-right: 10px;
}

.profile-buttons {
    width: 100%;
    height: 165px;
    position: relative;
}

.profile-buttons .btn-default {
    position: absolute;
    bottom: 50px;
    left: 0;
    display: block;
    width: 100%;
}

.profile-buttons .btn-primary {
    position: absolute;
    bottom: 0px;
    left: 0;
    display: block;
    width: 100%;
}

.ratings-average-box {
    width: 100%;
    height: 100px;
    #gradient > .vertical(#5ee450, #37c428);
    position: relative;
}

    .ratings-average {
        position: absolute;
        width:100%;
        top: 20px;
        left: 0;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
    }

    .ratings-gesamt {
        position: absolute;
        width: 100%;
        top: 46px;
        left: 0;
        text-align: center;
        color: #ffffff;
    }

    .ratings-circle {
        font-size: 90px;
        position: absolute;
        top: -42px;
        width: 100%;
        text-align: center;
        color: #ffffff;
    }

.ratings-second-box {
    width: 100%;
    height: 65px;
    #gradient > .vertical(#f1f1f1, #e3e3e3);
    position: relative;
}

    .ratings-second-box .ratings-evaluations-num {
        position: absolute;
        top: 10px;
        left: 0;
        text-align: center;
        width: 100%;
    }

    .ratings-second-box .recommendation-percentage {
        position: absolute;
        bottom: 10px;
        left: 0;
        text-align: center;
        width: 100%;
    }

.grade.sehrgut {color: @sehrgut;}
.grade.gut {color: @gut;}
.grade.befriedigend {color: @befriedigend;}
.grade.ausreichend {color: @ausreichend;}
.grade.mangelhaft {color: @mangelhaft;}
.grade.ungenuegend {color: @ungenuegend;}

.ratings-overview img {
    display: inline;
}

.ratings-overview .grade-wrapper {
    position: relative;
    width: 70%;
    margin-left: 15%;
}

@media (min-width: @screen-sm-min) {
    .ratings-overview .grade-wrapper {
        width: 100%;
        margin: 0;
    }
}

.ratings-overview .grade-wrapper .grade {
    font-weight: bold;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 30%;
    font-size: 36px;
}
@media (min-width: @screen-sm-min) {.ratings-overview .grade-wrapper .grade {top: 10%;}}
@media (min-width: @screen-md-min) {.ratings-overview .grade-wrapper .grade {top: 20%;}}
@media (min-width: @screen-lg-min) {.ratings-overview .grade-wrapper .grade {top: 25%;}}

.ratings-overview .grade-label {
    font-size: 11px;
    margin-bottom: 15px;
    display: inline-block;
}

.ratings-overview table {
    width: 50%;
}

@media (min-width: @screen-md-min) {.ratings-overview table {width: 100%;}}


.patient-evaluations .grade-wrapper {
    position: relative;
}

.patient-evaluations .grade-wrapper .grade {
    font-weight: bold;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 20%;
}
@media (min-width: @screen-sm-min) {.patient-evaluations .grade-wrapper .grade {top: 8%;}}
@media (min-width: @screen-md-min) {.patient-evaluations .grade-wrapper .grade {top: 18%;}}
@media (min-width: @screen-lg-min) {.patient-evaluations .grade-wrapper .grade {top: 25%;}}


#doc-profile .more-info h3 {
    color: @brand-secondary;
    font-size: 15px;
    margin-bottom: 10px;
}

#doc-profile .more-info p, #doc-profile .more-info ol {
    margin: 0;
}

#doc-profile .more-info a {
    font-weight: 600;
    color: @brand-primary;
}

#doc-profile .more-info a:hover {
    text-decoration: underline;
}



// Latest entries, footer
#latest-entries {
    margin-top: 25px;
}
#latest-entries h3 {
    margin: 0;
}
#latest-entries address {
    margin-bottom: 0;
}
#latest-entries .row {
    margin-bottom: 15px;
}
#latest-entries .doc-desc {
    padding-left: 0;
}

footer {
    margin: 100px 0 0;
    padding: 20px 0;
    background: #ffffff;
    border-top: 1px solid #e6e6e6;
}

footer #socialmedia-icons a {
    display: inline-block;
    background: #ddd;
    height: 40px;
    width: 40px;
    border-radius: 5px;
    text-align: center;
    line-height: 50px;
    margin-right: 10px;
}
footer #socialmedia-icons a, footer #socialmedia-icons a:visited {
    color: #ffffff;
}
footer #socialmedia-icons a:hover, footer #socialmedia-icons a:active {
    color: @brand-primary;
}

ul#footer-links {
}
